/* global FormData */
// import { push } from 'react-router-redux'
import { getJSON, postData } from '../../libs/data'
import { fetchUserArtists } from '../Artist/ArtistActions'
import { trackEvent } from '../../libs/analytics'

export const RELOAD_ARTIST_ADMIN = 'RELOAD_ARTIST_ADMIN'
export const RELOADING_ARTIST_ADMIN = 'RELOADING_ARTIST_ADMIN'
export const RELOAD_ARTIST_SCORE_ADMIN = 'RELOAD_ARTIST_SCORE_ADMIN'
export const RELOADING_ARTIST_SCORE_ADMIN = 'ARTIST_ADMIN/RELOADING_ARTIST_SCORE_ADMIN'
export const FETCH_ARTIST_ADMIN_PENDING = 'FETCH_ARTIST_ADMIN_PENDING'
export const FETCH_ARTIST_ADMIN_FULFILLED = 'FETCH_ARTIST_ADMIN_FULFILLED'
export const FETCH_ARTIST_ADMIN_REJECTED = 'FETCH_ARTIST_ADMIN_REJECTED'

export const FETCH_ARTIST_REPERTOIRE_ADMIN_PENDING = 'FETCH_ARTIST_REPERTOIRE_ADMIN_PENDING'
export const FETCH_ARTIST_REPERTOIRE_ADMIN_FULFILLED = 'FETCH_ARTIST_REPERTOIRE_ADMIN_FULFILLED'
export const FETCH_ARTIST_REPERTOIRE_ADMIN_REJECTED = 'FETCH_ARTIST_REPERTOIRE_ADMIN_REJECTED'
export const CHANGE_ARTIST_REPERTOIRE_ADMIN_ORDER = 'CHANGE_ARTIST_REPERTOIRE_ADMIN_ORDER'

export const DELETE_ARTIST_ADMIN_DATA = 'DELETE_ARTIST_ADMIN_DATA'

export const SAVE_ARTIST_ADMIN_DATA = 'SAVE_ARTIST_ADMIN_DATA'
export const SAVING_ARTIST_ADMIN_DATA = 'SAVING_ARTIST_ADMIN_DATA'
export const SET_ARTIST_ADMIN_FORM_DATA = 'SET_ARTIST_ADMIN_FORM_DATA'
export const INIT_ARTIST_ADMIN_FORM_DATA = 'INIT_ARTIST_ADMIN_FORM_DATA'
export const RESET_ARTIST_ADMIN_DATA = 'RESET_ARTIST_ADMIN_DATA'

export const SET_HOME_LOCATION_ADMIN_DATA = 'SET_HOME_LOCATION_ADMIN_DATA'
export const SET_HOME_LOCATION_ADMIN_DATA_ERROR = 'SET_HOME_LOCATION_ADMIN_DATA_ERROR'
export const UNSET_HOME_LOCATION_ADMIN_DATA = 'UNSET_HOME_LOCATION_ADMIN_DATA'
export const UNSET_HOME_LOCATION_ADMIN_DATA_ERROR = 'UNSET_HOME_LOCATION_ADMIN_DATA_ERROR'

export const ADD_PERFORM_LOCATION_ADMIN_DATA = 'ADD_PERFORM_LOCATION_ADMIN_DATA'
export const ADD_PERFORM_LOCATION_ADMIN_DATA_ERROR = 'ADD_PERFORM_LOCATION_ADMIN_DATA_ERROR'
export const REMOVE_PERFORM_LOCATION_ADMIN_DATA = 'REMOVE_PERFORM_LOCATION_ADMIN_DATA'

export const ADD_REPERTOIRE_SONG_ARTIST_ADMIN_DATA = 'ADD_REPERTOIRE_SONG_ARTIST_ADMIN_DATA'
export const ADD_REPERTOIRE_SONG_ARTIST_ADMIN_DATA_ERROR =
  'ADD_REPERTOIRE_SONG_ARTIST_ADMIN_DATA_ERROR'
export const REMOVE_REPERTOIRE_SONG_ARTIST_ADMIN_DATA = 'REMOVE_REPERTOIRE_SONG_ARTIST_ADMIN_DATA'

export const UPDATE_ARTIST_ADMIN_IMAGE = 'UPDATE_ARTIST_ADMIN_IMAGE'
export const REMOVE_ARTIST_ADMIN_IMAGE = 'REMOVE_ARTIST_ADMIN_IMAGE'
export const MOVE_ARTIST_ADMIN_IMAGE = 'MOVE_ARTIST_ADMIN_IMAGE'

export const MOVE_ARTIST_ADMIN_AUDIO = 'MOVE_ARTIST_ADMIN_AUDIO'

export const PUBLISH_ARTIST_FULFILLED = 'ARTIST/PUBLISH_FULFILLED'
export const PUBLISH_ARTIST_ERROR = 'ARTIST/PUBLISH_ERROR'

export const UNPUBLISH_ARTIST_FULFILLED = 'ARTIST/UNPUBLISH_FULFILLED'
export const UNPUBLISH_ARTIST_ERROR = 'ARTIST/UNPUBLISH_ERROR'

export const publishArtistAction = (id) => (dispatch) => {
  const url = '/api/v1/artistAdmin/publish'
  const data = new FormData()
  data.append('id', id)
  postData(url, data).then(
    (json) => {
      dispatch({
        type: PUBLISH_ARTIST_FULFILLED,
        data: json,
      })
      dispatch(fetchUserArtists())
    },
    () => {
      dispatch({
        type: PUBLISH_ARTIST_ERROR,
      })
    },
  )
}

export const unpublishArtistAction = (id) => (dispatch) => {
  const url = '/api/v1/artistAdmin/unpublish'
  const data = new FormData()
  data.append('id', id)
  postData(url, data).then(
    (json) => {
      dispatch({
        type: UNPUBLISH_ARTIST_FULFILLED,
        data: json,
      })
      dispatch(fetchUserArtists())
    },
    () => {
      dispatch({
        type: UNPUBLISH_ARTIST_ERROR,
      })
    },
  )
}

export const initArtistFormData = () => (dispatch, getState) => {
  const artistData = getState().artistAdmin.data
  const formData = { ...artistData }

  dispatch({
    type: INIT_ARTIST_ADMIN_FORM_DATA,
    form: formData,
  })
}

export const resetArtistAdmin = () => (dispatch) => {
  dispatch({
    type: RESET_ARTIST_ADMIN_DATA,
  })
}

export const setArtistFormData = (obj) => (dispatch) => {
  dispatch({
    type: SET_ARTIST_ADMIN_FORM_DATA,
    form: obj,
  })
}

export const moveArtistAudio = (id, pos) => (dispatch, getState) => {
  const data = new FormData()
  const artist = getState().artistAdmin.data
  const url = '/api/v1/artistAdmin/moveAudio'
  data.append('id', artist.id)
  data.append('audioId', id)
  data.append('toPos', pos)
  postData(url, data).then((json) => {
    dispatch({
      type: MOVE_ARTIST_ADMIN_AUDIO,
      data: json,
    })
  })
}

export const changeArtistName = (name) => (dispatch, getState) => {
  dispatch({
    type: SAVING_ARTIST_ADMIN_DATA,
  })
  const data = new FormData()
  const artist = getState().artistAdmin.data
  const url = '/api/v1/artistAdmin/update'
  data.append('id', artist.id)
  data.append('name', name)
  postData(url, data).then((json) => {
    dispatch({
      type: SAVE_ARTIST_ADMIN_DATA,
      data: json,
    })
    dispatch(fetchUserArtists())
  })
}
/* WANT TO DEPRECATE USAGE OF EXTERNAL saveArtistData. Doing this to log it */

const internalSaveArtistData = (inputData) => (dispatch, getState) => {
  dispatch({
    type: SAVING_ARTIST_ADMIN_DATA,
  })
  const data = new FormData()
  const artist = getState().artistAdmin.data
  let newArtist = true
  let url = '/api/v1/artistAdmin/create'
  if (artist.id) {
    url = '/api/v1/artistAdmin/update'
    newArtist = false
    // This can happend if the form object is not initiated first.
    if (!inputData.id) {
      data.append('id', artist.id)
    }
  }
  let type = ''
  Object.keys(inputData).forEach((key) => {
    type = typeof inputData[key]

    switch (type) {
      case 'array':
      case 'object': {
        data.append(key, JSON.stringify(inputData[key]))
        break
      }
      default: {
        data.append(key, inputData[key])
        break
      }
    }
  })
  postData(url, data).then(
    (json) => {
      dispatch({
        type: SAVE_ARTIST_ADMIN_DATA,
        data: json,
      })
      if (newArtist) {
        trackEvent('artist-profile', 'artist-profile-created')
        dispatch(fetchUserArtists())
      }
    },
    () => {
      // TODO: Handle this
      console.log('GOT ERROR') // eslint-disable-line
    },
  )
}

const reloadArtist = () => (dispatch, getState) => {
  const artist = getState().artistAdmin
  const url = `/api/v1/artist/getOne?id=${artist.data.id}`
  dispatch({ type: RELOADING_ARTIST_ADMIN })
  getJSON(url).then((json) => {
    dispatch({
      type: RELOAD_ARTIST_ADMIN,
      data: json,
    })
  })
}

export const reloadArtistScoreAction = () => (dispatch, getState) => {
  const artist = getState().artistAdmin
  const url = `/api/v1/artist/getOne?id=${artist.data.id}`
  dispatch({ type: RELOADING_ARTIST_SCORE_ADMIN })
  getJSON(url).then((json) => {
    dispatch({
      type: RELOAD_ARTIST_SCORE_ADMIN,
      data: json,
    })
  })
}

/* USE THIS */
export const saveArtistFormData = () => (dispatch, getState) => {
  const formData = getState().artistAdmin.form
  dispatch(internalSaveArtistData(formData))
}

export const fetchOneArtistBySlug = (req, slug) => (dispatch) => {
  if (!slug) {
    dispatch({
      type: FETCH_ARTIST_ADMIN_REJECTED,
    })
    return null
  }

  const url = `/api/v1/artist/getOne?slug=${slug}`
  dispatch({ type: FETCH_ARTIST_ADMIN_PENDING })
  return getJSON(url, { req }).then(
    (json) => {
      dispatch({
        type: FETCH_ARTIST_ADMIN_FULFILLED,
        payload: {
          data: json,
          receivedAt: Date.now(),
        },
      })
    },
    (error) => {
      dispatch({ type: FETCH_ARTIST_ADMIN_REJECTED })
    },
  )
}

export const fetchOneArtistBySlugIfNeeded =
  (req = {}, slug) =>
  (dispatch, getState) => {
    const artist = getState().artistAdmin
    if (artist.data && artist.data.slug === slug) {
      if (artist.isFetching) {
        return
      }
    }
    dispatch(fetchOneArtistBySlug(req, slug))
  }

/* IMAGES */
export const reloadArtistImages = () => (dispatch) => {
  dispatch(reloadArtist())
}

export const updateArtistImage = (imageUpdate) => async (dispatch, getState) => {
  const data = new FormData()
  const artist = getState().artistAdmin.data
  const url = '/api/v1/artistAdmin/updateImage'
  data.append('id', artist.id)
  data.append('imageId', imageUpdate.id)
  Object.keys(imageUpdate).forEach((key) => {
    if (key !== 'id') {
      if (imageUpdate[key] !== null && typeof imageUpdate[key] === 'object') {
        data.append(key, JSON.stringify(imageUpdate[key]))
      } else {
        data.append(key, imageUpdate[key])
      }
    }
  })

  const result = await postData(url, data)
  dispatch(reloadArtist())
}

export const moveArtistImage = (imageId, pos) => (dispatch, getState) => {
  const data = new FormData()
  const artist = getState().artistAdmin.data
  const url = '/api/v1/artistAdmin/moveImage'
  data.append('id', artist.id)
  data.append('imageId', imageId)
  data.append('toPos', pos)
  postData(url, data).then((json) => {
    dispatch({
      type: MOVE_ARTIST_ADMIN_IMAGE,
      data: json,
    })
    dispatch(reloadArtist())
  })
}

export const removeArtistImage = (imageId) => (dispatch, getState) => {
  const artist = getState().artistAdmin.data
  const url = '/api/v1/artistAdmin/removeImage'
  const data = new FormData()
  data.append('id', artist.id)
  data.append('imageId', imageId)
  postData(url, data).then((json) => {
    dispatch({
      type: REMOVE_ARTIST_ADMIN_IMAGE,
      data: json,
    })
    dispatch(reloadArtist())
  })
}

/* HOMELOCATION */

export const setHomeLocation = (locationId) => (dispatch, getState) => {
  const artist = getState().artistAdmin.data
  const url = '/api/v1/artistAdmin/setHomeLocation'

  const data = new FormData()
  data.append('id', artist.id)
  data.append('locationId', locationId)
  dispatch({
    type: SAVING_ARTIST_ADMIN_DATA,
  })
  postData(url, data).then(
    (json) => {
      dispatch({
        type: SET_HOME_LOCATION_ADMIN_DATA,
        data: json,
      })
    },
    () => {
      dispatch({
        type: SET_HOME_LOCATION_ADMIN_DATA_ERROR,
        errorCode: '',
        errorMessage: '',
      })
    },
  )
}

export const unsetHomeLocation = () => (dispatch, getState) => {
  const artist = getState().artistAdmin.data
  const url = '/api/v1/artistAdmin/unsetHomeLocation'

  const data = new FormData()
  data.append('id', artist.id)

  dispatch({
    type: SAVING_ARTIST_ADMIN_DATA,
  })
  postData(url, data).then(
    (json) => {
      dispatch({
        type: UNSET_HOME_LOCATION_ADMIN_DATA,
        data: json,
      })
    },
    () => {
      dispatch({
        type: UNSET_HOME_LOCATION_ADMIN_DATA_ERROR,
        errorCode: '',
        errorMessage: '',
      })
    },
  )
}

/* PERFORMLOCATION */

export const addPerformLocation = (locationId) => (dispatch, getState) => {
  const artist = getState().artistAdmin.data
  const url = '/api/v1/artistAdmin/addPerformLocation'

  const data = new FormData()
  data.append('id', artist.id)
  data.append('locationId', locationId)
  dispatch({
    type: SAVING_ARTIST_ADMIN_DATA,
  })
  postData(url, data).then(
    (json) => {
      dispatch({
        type: ADD_PERFORM_LOCATION_ADMIN_DATA,
        data: json,
      })
    },
    () => {
      dispatch({
        type: ADD_PERFORM_LOCATION_ADMIN_DATA_ERROR,
        errorCode: '',
        errorMessage: '',
      })
    },
  )
}

export const removePerformLocation = (locationId) => (dispatch, getState) => {
  const artist = getState().artistAdmin.data
  const url = '/api/v1/artistAdmin/removePerformLocation'

  const data = new FormData()
  data.append('id', artist.id)
  data.append('locationId', locationId)
  dispatch({
    type: SAVING_ARTIST_ADMIN_DATA,
  })
  postData(url, data).then((json) => {
    dispatch({
      type: REMOVE_PERFORM_LOCATION_ADMIN_DATA,
      data: json,
    })
  })
}

/* REPERTOIRE */
const reloadRepertoire = () => (dispatch, getState) => {
  const { repertoire } = getState().artistAdmin
  const artist = getState().artistAdmin.data
  const url = `/api/v1/artistAdmin/getRepertoire?id=${artist.id}&sortField=${repertoire.sortField}&sortDir=${repertoire.sortDir}`

  dispatch({ type: FETCH_ARTIST_REPERTOIRE_ADMIN_PENDING })
  return getJSON(url).then(
    (json) => {
      dispatch({
        type: FETCH_ARTIST_REPERTOIRE_ADMIN_FULFILLED,
        payload: {
          data: json,
          receivedAt: Date.now(),
        },
      })
    },
    (error) => {
      dispatch({ type: FETCH_ARTIST_REPERTOIRE_ADMIN_REJECTED })
    },
  )
}

export const changeRepertoireOrder = (sortField, sortDir) => (dispatch) => {
  dispatch({
    type: CHANGE_ARTIST_REPERTOIRE_ADMIN_ORDER,
    sortField,
    sortDir,
  })
  dispatch(reloadRepertoire())
}

export const fetchRepertoire = () => (dispatch) => {
  dispatch(reloadRepertoire())
}

export const addRepertoireSong = (songId) => (dispatch, getState) => {
  const artist = getState().artistAdmin.data
  const repertoire = getState().artistAdmin.repertoire.data
  const url = '/api/v1/artistAdmin/addRepertoireSong'
  const data = new FormData()
  data.append('songId', songId)
  data.append('id', artist.id)
  dispatch({
    type: SAVING_ARTIST_ADMIN_DATA,
  })
  postData(url, data).then(
    (json) => {
      dispatch({
        type: ADD_REPERTOIRE_SONG_ARTIST_ADMIN_DATA,
        data: json,
        songId,
      })
      // If no repertoire, this will be the first, and it will affect the score.
      dispatch(reloadArtistScoreAction())
      dispatch(reloadRepertoire())
    },
    () => {
      dispatch({
        type: ADD_REPERTOIRE_SONG_ARTIST_ADMIN_DATA_ERROR,
        errorCode: 'artistrepertoire_duplicate',
        errorMessage: 'Du har allerede lagt til denne sangen',
      })
    },
  )
}

export const removeRepertoireSong = (songId) => (dispatch, getState) => {
  const artist = getState().artistAdmin.data
  const repertoire = getState().artistAdmin.repertoire.data
  const url = '/api/v1/artistAdmin/removeRepertoireSong'
  const data = new FormData()
  data.append('songId', songId)
  data.append('id', artist.id)
  dispatch({
    type: SAVING_ARTIST_ADMIN_DATA,
  })
  postData(url, data).then(
    (json) => {
      dispatch({
        type: REMOVE_REPERTOIRE_SONG_ARTIST_ADMIN_DATA,
        data: json,
        songId,
      })
      // If this is the last song, and we are going to remove it? Let's update the artist.
      dispatch(reloadArtistScoreAction())
      dispatch(reloadRepertoire())
    },
    (error) => {
      console.log('error')
    },
  )
}

export const saveArtistData = (inputData) => internalSaveArtistData(inputData)
